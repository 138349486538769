import styled from 'styled-components'
import GooglePlayBadge from '../static/images/GooglePlay.svg'
import AppStoreBadge from '../static/images/AppStore.svg'

function ServiceDetail({ image, explanation, appStoreLink, googlePlayLink }) {
  return (
    <ServiceDetailContainer>
      <LogoImage src={image} />
      <RightSection>
        <ExplanationSection>
          {explanation}
        </ExplanationSection>
        <StoreLinksSection>
          <Spacer />
          {appStoreLink && (
            <StoreLink href={appStoreLink} target="_blank">
              <AppStoreBadgeImage src={AppStoreBadge} />
            </StoreLink>
          )}
          {googlePlayLink && (
            <StoreLink href={googlePlayLink} target="_blank">
              <GooglePlayBadgeImage src={GooglePlayBadge} />
            </StoreLink>
          )}
        </StoreLinksSection>
      </RightSection>
    </ServiceDetailContainer>
  )
}
 
export default ServiceDetail

const ServiceDetailContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0 6rem;
`;

const LogoImage = styled.img`
  width: 6rem;
  height: 6rem;
  border-radius: 1rem;
  margin: 0 2rem 0 0;

  transform-style: preserve-3d;
  box-shadow:
    0.5rem 0.5rem 0.5rem 0 rgba(34, 33, 81, 0.1);

  transition:
    .4s ease-in-out transform,
    .4s ease-in-out box-shadow;

  &:hover {
    box-shadow:
      0.5rem 0.5rem 0.5rem 0 rgba(34, 33, 81, 0.01),
      0.75rem 0.75rem 0.75rem 0 rgba(34, 33, 81, 0.25);
    transform:
      translate3d(0px, -1rem, 0px);
  }
`;

const AppStoreBadgeImage = styled.img`
  width: 5rem;
  height: 1.5rem;
  object-fit: contain;
`;

const GooglePlayBadgeImage = styled.img`
  width: 5rem;
  height: 1.5rem;
  object-fit: contain;
`;

const RightSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`;

const ExplanationSection = styled.div`
  font-size: 1.5rem;
  font-weight: 400;
  
  animation: wobble 1s 1;

  @keyframes wobble {
    25% {
      transform: rotate(2deg);
    }
    50% {
      transform: rotate(-4deg);
    }
    75% {
      transform: rotate(3deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
`;

const Spacer = styled.div`
  flex: 1;
`;

const StoreLinksSection = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-left: auto;
`;

const StoreLink = styled.a`
  text-decoration: none;
  transition: opacity 0.2s;

  &:hover {
    opacity: 0.8;
  }
`;
